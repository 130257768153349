<template>
  <v-container class="app-dual-list">
    <div style="display: flex">
      <div style="flex: 1">
        <v-list flat subheader :style="listStyles">
          <v-subheader v-if="titleParent">{{ titleParent }}</v-subheader>
          <v-list-item-group
            v-model="selectedParent"
            active-class="active-parent"
          >
            <v-list-item
              v-for="(item, i) in parentItems"
              :key="`left_${i}`"
              :value="item"
              dense
            >
              <template>
                <v-list-item-action>
                  <v-badge
                    v-if="getChildItemCount(item)"
                    inline
                    :content="getChildItemCount(item)"
                  ></v-badge>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    <slot name="parent-item" :item="item">
                      <slot name="item" :item="item"></slot>
                    </slot>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
      <div style="flex: 1">
        <v-list flat subheader :style="listStyles">
          <v-subheader v-if="titleChild">{{ titleChild }}</v-subheader>
          <v-list-item-group
            v-model="cpSelectedChildItems"
            :value-comparator="childItemComparator"
            multiple
            active-class=""
          >
            <v-list-item
              v-for="(item, i) in childItems"
              :key="`right_${i}`"
              :value="item"
              dense
            >
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    <slot name="child-item" :item="item">
                      <slot name="item" :item="item"></slot>
                    </slot>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'AppCascadeList',
  props: {
    titleParent: {
      type: String
    },
    titleChild: {
      type: String
    },
    value: {
      type: Array,
      required: true
    },
    height: {
      type: String,
      default: null
    },
    parentItems: {
      type: Array,
      required: true
    },
    getChildItems: {
      type: Function,
      required: true
    },
    childItemComparator: {
      type: Function,
      required: false
    },
    selectedChildItems: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      selectedParent: {},
      childItems: []
    }
  },
  computed: {
    listStyles() {
      const style = {}
      if (this.height) {
        style.height = this.height
        style.overflowY = 'scroll'
      }
      return style
    },
    cpSelectedChildItems: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    selectedParent: {
      handler: async function (val) {
        const items = await this.getChildItems(val)
        this.childItems = items ?? []
      }
      // immediate: true
    }
  },
  methods: {
    getChildItemCount(parentItem) {
      const items = this.selectedChildItems(parentItem, this.value)
      return items?.length ?? null
    }
  }
}
</script>

<style scoped lang="scss">
.v-list-item.v-list-item--active.active-parent {
  //background-color: $secondary;
  background-color: rgba(66, 194, 255, 0.2);
}
</style>
