<template>
  <v-container class="app-dual-list">
    <div style="display: flex">
      <div style="flex: 1">
        <v-list flat subheader :style="listStyles">
          <v-subheader v-if="titleLeft">{{ titleLeft }}</v-subheader>

          <v-list-item-group v-model="selectedLeft" multiple active-class="">
            <v-list-item
              v-for="(item, i) in itemsLeft"
              :key="`left_${i}`"
              :value="item"
              dense
            >
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    <slot name="left-item" :item="item">
                      <slot name="item" :item="item"></slot>
                    </slot>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
      <div
        style="display: flex; flex-direction: column; justify-content: center"
      >
        <v-btn icon @click="moveLeftAll">
          <v-icon>mdi-chevron-double-left</v-icon>
        </v-btn>
        <v-btn icon @click="moveLeft">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon @click="moveRight">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon @click="moveRightAll">
          <v-icon>mdi-chevron-double-right</v-icon>
        </v-btn>
      </div>
      <div style="flex: 1">
        <v-list flat subheader :style="listStyles">
          <v-subheader v-if="titleRight">{{ titleRight }}</v-subheader>

          <v-list-item-group v-model="selectedRight" multiple active-class="">
            <v-list-item
              v-for="(item, i) in itemsRight"
              :key="`right_${i}`"
              :value="item"
              dense
            >
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    <slot name="right-item" :item="item">
                      <slot name="item" :item="item"></slot>
                    </slot>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'AppDualList',
  props: {
    titleLeft: {
      type: String
    },
    titleRight: {
      type: String
    },
    /// This list of all items
    source: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    startFromRight: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selectedLeft: [],
      selectedRight: []
    }
  },
  computed: {
    listStyles() {
      const style = {}
      if (this.height) {
        style.height = this.height
        style.overflowY = 'scroll'
      }
      return style
    },
    itemsLeft() {
      return this.startFromRight
        ? this.source.filter((item) => this.value.includes(item))
        : this.source.filter((item) => !this.value.includes(item))
    },
    itemsRight() {
      return this.startFromRight
        ? this.source.filter((item) => !this.value.includes(item))
        : this.source.filter((item) => this.value.includes(item))
    }
  },
  methods: {
    moveRight() {
      if (this.startFromRight) {
        const value = this.value.filter(
          (item) => !this.selectedLeft.includes(item)
        )
        this.$emit('input', value)
      } else {
        const value = [...this.value, ...this.selectedLeft]
        this.$emit('input', value)
      }
      this.selectedLeft = []
    },
    moveRightAll() {
      if (this.startFromRight) {
        const value = []
        this.$emit('input', value)
      } else {
        const value = [...this.value, ...this.itemsLeft]
        this.$emit('input', value)
      }
      this.selectedLeft = []
    },
    moveLeft() {
      if (this.startFromRight) {
        const value = [...this.value, ...this.selectedRight]
        this.$emit('input', value)
      } else {
        const value = this.value.filter(
          (item) => !this.selectedRight.includes(item)
        )
        this.$emit('input', value)
      }
      this.selectedRight = []
    },
    moveLeftAll() {
      if (this.startFromRight) {
        const value = [...this.value, ...this.itemsRight]
        this.$emit('input', value)
      } else {
        const value = []
        this.$emit('input', value)
      }
      this.selectedRight = []
    }
  }
}
</script>

<style scoped lang="scss"></style>
