const defaultTableParams = (options = null) => {
  let params = {}
  if (options) {
    const offset = (options.page - 1) * options.itemsPerPage
    const ordering = options.sortBy
      ? options.sortBy
          .reduce(
            (acc, value, index) => [
              ...acc,
              `${
                options.sortDesc && options.sortDesc[index] ? '-' : ''
              }${value.replace('.', '__')}`
            ],
            []
          )
          .join(',')
      : null

    params = {
      ...params,
      limit: options.itemsPerPage,
      offset: offset,
      search: options.search,
      ordering: ordering,
      ...options.multiSearch
    }
  }
  return params
}

export { defaultTableParams }
